import { methodService , apiService } from "./apiService";

export const URL = {
    BASE_URL: "/donations"
}

export function apiSendDonation(data) {
    return apiService(
        URL.BASE_URL,
        methodService.POST,
        data,
        null
    )
}