import React from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Nav, Navbar} from 'react-bootstrap';
import {Grid, Box} from '@material-ui/core';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import './index.css';

export function Header() {
  const history = useHistory();
  const OurPublicationSubMenu = () => {
    return (
      <div
        id="ourPublicationSubMenuItem"
        dataId="ourPublicationSubMenuItem"
        className="container-submenu submenu-item">
        <Nav.Link
          className="container-link"
          onClick={() => {
            window.location.href = 'https://www.lionsbefrienders.org.sg/our-books/';
          }}>
          <span className="link-submenu">Our Books</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() => {
            window.location.href =
              'https://www.lionsbefrienders.org.sg/annual-reports-and-newsletters/';
          }}>
          <span className="link-submenu">Annual Reports & Newsletters</span>
        </Nav.Link>
      </div>
    );
  };

  const EventSubMenu = () => {
    return (
      <div
        id="eventCampaignSubMenuItem"
        dataId="eventsSubMenuItem"
        className="container-submenu submenu-item">
        <Nav.Link
          className="container-link"
          onClick={() => {
            window.location.href = 'https://www.lionsbefrienders.org.sg/lbeflagday2021/';
          }}>
          <span className="link-submenu">eFlag Day 2021</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() => {
            window.location.href = 'https://www.lionsbefrienders.org.sg/virtualkindnessgarden/';
          }}>
          <span className="link-submenu">Virtual Kindness Garden</span>
        </Nav.Link>
      </div>
    );
  };

  const AboutUsMenu = () => {
    return (
      <div dataId="aboutSubMenu" className="container-submenu">
        <Nav.Link
          className="container-link"
          onClick={() => {
            window.location.href = 'https://www.lionsbefrienders.org.sg/about/';
          }}>
          <span className="link-submenu">About Lions Befrienders</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() => {
            window.location.href = 'https://www.lionsbefrienders.org.sg/management-board/';
          }}>
          <span className="link-submenu">Management Board</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() => {
            window.location.href =
              'https://www.lionsbefrienders.org.sg/milestones-and-accolades-2/';
          }}>
          <span className="link-submenu">Management Team</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() => {
            window.location.href = 'https://www.lionsbefrienders.org.sg/organisational-structure/';
          }}>
          <span className="link-submenu">Organisational Structure</span>
        </Nav.Link>
        <Nav.Link
          dataTarget="ourPublicationSubMenuItem"
          className="container-link nav-link-arrow submenu-link menu-with-child"
          id="ourPublication">
          <span className="link-submenu">Our Publications</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
          <OurPublicationSubMenu />
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() => {
            window.location.href = 'https://www.lionsbefrienders.org.sg/careers/';
          }}>
          <span className="link-submenu">Careers</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() => {
            window.location.href = 'https://www.lionsbefrienders.org.sg/milestones-and-accolades/';
          }}>
          <span className="link-submenu">Milestones & Accolades</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() => {
            window.location.href = 'https://www.lionsbefrienders.org.sg/lb-in-the-news/';
          }}>
          <span className="link-submenu">LB in the News</span>
        </Nav.Link>
      </div>
    );
  };

  const ServicesMenu = () => {
    return (
      <div dataId="servicesSubMenu" className="container-submenu">
        <Nav.Link
          className="container-link"
          onClick={() => {
            window.location.href = 'https://www.lionsbefrienders.org.sg/activeageingcentre/';
          }}>
          <span className="link-submenu">Active Ageing Centre</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() => {
            window.location.href = 'https://www.lionsbefrienders.org.sg/services-befriending/';
          }}>
          <span className="link-submenu">Befriending</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() => {
            window.location.href =
              'https://www.lionsbefrienders.org.sg/services-home-personal-care/';
          }}>
          <span className="link-submenu">Home Personal Care</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() => {
            window.location.href =
              'https://www.lionsbefrienders.org.sg/services-sac-cluster-support/';
          }}>
          <span className="link-submenu">SAC Cluster Support</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() => {
            window.location.href =
              'https://www.lionsbefrienders.org.sg/services-senior-activity-centre/';
          }}>
          <span className="link-submenu">Senior Activity Centre</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() => {
            window.location.href =
              'https://www.lionsbefrienders.org.sg/services-senior-group-home/';
          }}>
          <span className="link-submenu">Senior Group Home</span>
        </Nav.Link>
      </div>
    );
  };

  const GetInvolvedMenu = () => {
    return (
      <div dataId="getInvolvedSubMenu" className="container-submenu">
        <Nav.Link
          className="container-link"
          onClick={() => {
            window.location.href = 'https://www.lionsbefrienders.org.sg/volunteer-programmes/';
          }}>
          <span className="link-submenu">Volunteer Programmes</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() => {
            window.location.href = 'https://www.lionsbefrienders.org.sg/partnerships/';
          }}>
          <span className="link-submenu">Partnerships</span>
        </Nav.Link>
        <Nav.Link
          id="eventCampaigns"
          dataTarget="eventsSubMenuItem"
          className="container-link submenu-link nav-link-arrow menu-with-child">
          <span className="link-submenu">Events & Campaigns</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
          <EventSubMenu />
        </Nav.Link>
      </div>
    );
  };

  const ContactUsMenu = () => {
    return (
      <div dataId="contactUsSubMenu" className="container-submenu">
        <Nav.Link
          className="container-link"
          onClick={() => {
            window.location.href = 'https://www.lionsbefrienders.org.sg/enquiry/';
          }}>
          <span className="link-submenu">Enquiry</span>
        </Nav.Link>
        <Nav.Link
          className="container-link"
          onClick={() => {
            window.location.href = 'https://www.lionsbefrienders.org.sg/contact-us/';
          }}>
          <span className="link-submenu">Our Locations</span>
        </Nav.Link>
      </div>
    );
  };

  const gotoDonation = () => {
    if (process.env.REACT_APP_BUILD_TYPE === 'development') {
      history.push('/donate');
    } else {
      history.push('/');
    }
  };

  return (
    <Navbar
      expand="xl"
      variant="dark"
      className="Navbar-container"
      style={{
        padding: '0px',
      }}>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            top: '10px',
            left: '2%',

            fontWeight: 'bold',
            fontSize: '18px',
            width: '95%',
            textDecoration: 'none',
            position: 'absolute',
            zIndex: 1000,
          }}
          className="me-auto navbar">
          <LazyLoadImage src="/Media/img-header/LB-Logo-White.png" className="nav-logo" />
          <Box>
            <Grid container alignItems="center" spacing={3}>
              <Grid item xs={12} lg={'auto'}>
                <Nav.Link
                  className="nav-link"
                  style={{
                    color: '#ffffff',
                    fontSize: '18px',
                    fontFamily: 'Roboto,Arial,Helvetica,sans-serif',
                  }}
                  onClick={() => {
                    window.location.href = 'https://www.lionsbefrienders.org.sg/';
                  }}>
                  Home
                </Nav.Link>
              </Grid>
              <Grid item xs={12} lg={'auto'}>
                <Nav.Link
                  className="nav-link nav-link-arrow about"
                  dataTarget="aboutSubMenu"
                  style={{
                    color: '#ffffff',
                    fontSize: '18px',
                    fontFamily: 'Roboto,Arial,Helvetica,sans-serif',
                  }}>
                  About Us
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                  <AboutUsMenu />
                </Nav.Link>
              </Grid>
              <Grid item xs={12} lg={'auto'}>
                <Nav.Link
                  className="nav-link nav-link-arrow"
                  dataTarget="servicesSubMenu"
                  style={{
                    color: '#ffffff',
                    fontSize: '18px',
                    fontFamily: 'Roboto,Arial,Helvetica,sans-serif',
                  }}>
                  Services
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                  <ServicesMenu />
                </Nav.Link>
              </Grid>
              <Grid item xs={12} lg={'auto'}>
                <Nav.Link
                  className="nav-link nav-link-arrow"
                  dataTarget="getInvolvedSubMenu"
                  style={{
                    color: '#ffffff',
                    fontSize: '18px',
                    fontFamily: 'Roboto,Arial,Helvetica,sans-serif',
                  }}>
                  Get Involved
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                  <GetInvolvedMenu />
                </Nav.Link>
              </Grid>
              <Grid item xs={12} lg={'auto'}>
                <Nav.Link
                  className="nav-link"
                  style={{
                    color: '#ffffff',
                    fontSize: '18px',
                    fontFamily: 'Roboto,Arial,Helvetica,sans-serif',
                  }}
                  onClick={() => {
                    window.location.href = 'https://www.lionsbefrienders.org.sg/refer-a-senior/';
                  }}>
                  Refer a Senior
                </Nav.Link>
              </Grid>
              <Grid item xs={12} lg={'auto'}>
                <Nav.Link
                  className="nav-link"
                  style={{
                    color: '#ffffff',
                    fontSize: '18px',
                    fontFamily: 'Roboto,Arial,Helvetica,sans-serif',
                  }}
                  onClick={() => {
                    window.location.href =
                      'https://www.lionsbefrienders.org.sg/inspiring-stories-2/';
                  }}>
                  Stories
                </Nav.Link>
              </Grid>
              <Grid item xs={12} lg={'auto'}>
                <Nav.Link
                  className="nav-link nav-link-arrow"
                  dataTarget="contactUsSubMenu"
                  style={{
                    color: '#ffffff',
                    fontSize: '18px',
                    fontFamily: 'Roboto,Arial,Helvetica,sans-serif',
                  }}>
                  Contact Us
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                  <ContactUsMenu />
                </Nav.Link>
              </Grid>

              <Button
                className="button"
                style={{
                  fontWeight: 'bold',
                  width: '121px',
                  height: '48px',
                  borderRadius: '100px',
                  backgroundColor: '#d94948',
                  borderColor: '#d94948',
                  fontFamily: 'Roboto',
                  color: '#ffffff',
                  marginLeft: '20px',
                  marginTop: '20px',
                  marginBottom: '20px',
                  cursor: 'pointer',
                }}
                onClick={gotoDonation}>
                DONATE
              </Button>
            </Grid>
          </Box>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
