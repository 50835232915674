import React, {useState, useEffect} from 'react';
import {Modal, Form, Button} from 'react-bootstrap';
import PaynowQRBase from 'paynowqr';
import QRCode from 'qrcode';
import {IoCopy} from 'react-icons/io5';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import './ModalPaymentMethod.css';
// import GiroPdf from '../FormDonate/giroPdf';

class PaynowQR extends PaynowQRBase {
  getOptions() {
    return {
      errorCorrectionLevel: 'H',
      type: 'image/webp',
      quality: 0.3,
      margin: 6,
      color: {
        dark: '#90137B',
        light: '#FFFFFF',
      },
    };
  }

  async getQRCodeImageDataURL() {
    const options = this.getOptions();
    const text = this.output();
    const dataURL = await QRCode.toDataURL(text, options);
    return dataURL;
  }
}

export function ModalPaymentMethod({
  show,
  onHide,
  type,
  onConfirm,
  dataForm,
  donationNumber = null,
}) {
  const [isPrint, setIsPrint] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [src, setSrc] = useState('');
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [qrcode, setQrCode] = useState(null);
  const [dataQRCode, setDataQRCode] = useState({
    uen: 'S95SS0076A',
    amount: 10, // donation amount
    editable: true,
    refNumber: null, // donation id ="TQINV-10001"
    company: 'LBSA Donation Fund',
  });

  const officeAddress = [
    'Executive Director LIONS BEFRIENDERS SERVICE ASSOCIATION (SINGAPORE) Blk 130 Bukit Merah View #01-358 Singapore 150130',
  ];

  useEffect(() => {
    if (show) {
      setIsPrint(false);
    }
    if (type === 'PayNow') {
      setDataQRCode({
        ...dataQRCode,
        amount: dataForm.amount,
        refNumber: donationNumber,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    setQrCode(new PaynowQR(dataQRCode));
  }, [dataQRCode]);

  useEffect(() => {
    if (type === 'PayNow') {
      (async () => {
        let QRCodeImageDataURL = await qrcode.getQRCodeImageDataURL();
        setSrc(QRCodeImageDataURL);
      })();
    }
  });

  // function onPrint() {
  //   setIsPrint(true);
  // }

  function handleSubmit(event) {
    event.preventDefault();
    setLoadingConfirm(true);
    onConfirm();
  }

  function copyText() {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 500);
  }

  return (
    <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <span
            style={{
              color: '#2879d0',
              fontFamily: 'inherit',
              fontWeight: '400',
              fontSize: '32px',
            }}>
            {type === 'PayNow'
              ? 'Donation Paylah / Paynow'
              : type === 'Cheque'
              ? 'Cheque Instructions'
              : type === 'Giro'
              ? 'Giro Instructions'
              : null}
          </span>
          {type === 'PayNow' ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}>
              <LazyLoadImage style={{height: '200px', width: '200px'}} src={src} />
              <span
                style={{
                  color: '#2879d0',
                  fontSize: '22px',
                }}>
                PayNow / PayLah
              </span>
              <span
                style={{
                  textAlign: 'center',
                }}>
                Scan this QR code with your iBanking App
                <br />
                or
                <br />
                UEN: S95SS0076A
                <br />
                <p>Donation number: {donationNumber}</p>
                <br />
                *No official receipt will be issued
                <br />
                *Please indicate your NRIC in the Reference Field to be eligible for tax exemption
                <br />
                *Donation made by the PayLah app will not be eligible for tax exemption
              </span>
            </div>
          ) : (
            <div
              style={{
                marginTop: 20,
              }}>
              <span>
                {type === 'Cheque' ? (
                  <p>
                    {' '}
                    Cheque should be crossed and made payable to "Lions Befrienders" and mailed to
                    us:
                  </p>
                ) : null}

                <br />
                <i>
                  Executive Director <br />
                  LIONS BEFRIENDERS SERVICE ASSOCIATION (SINGAPORE) <br />
                  Blk 130 Bukit Merah View #01-358 <br />
                  Singapore 150130
                </i>
                <CopyToClipboard text={officeAddress} onCopy={() => copyText()}>
                  <IoCopy
                    className="button-copy"
                    style={{height: '12px', width: '12px', cursor: 'pointer'}}
                  />
                </CopyToClipboard>
                {isCopied && (
                  <span className="text-danger ml-1" style={{fontSize: '10px'}}>
                    Copied!
                  </span>
                )}
                <br />
                <br />
                <span>
                  Kindly indicate your full name, NRIC number, address, contact number and project
                  name (if it’s for a specific fundraising project) at the back of the cheque.
                </span>
              </span>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: 20,
            }}>
            <Button
              type="submit"
              style={{
                alignSelf: 'center',
                fontWeight: 'bold',
                width: '40%',
                height: '55px',
                borderRadius: '10px',
                fontFamily: 'Roboto,Arial,Helvetica,sans-serif',
                background: '#2879d0',
                border: '#2879d0',
                marginLeft: '5%',
              }}
              disabled={type !== 'Giro' ? false : !isPrint || loadingConfirm}>
              {loadingConfirm ? (
                <svg
                  style={{
                    height: '36px',
                    width: '36px',
                    display: 'inline-block',
                  }}
                  version="1.1"
                  id="L9"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 100 100"
                  enableBackground="new 0 0 0 0"
                  xmlSpace="preserve">
                  <path
                    fill="#fff"
                    d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                    <animateTransform
                      attributeName="transform"
                      attributeType="XML"
                      type="rotate"
                      dur="1s"
                      from="0 50 50"
                      to="360 50 50"
                      repeatCount="indefinite"
                    />
                  </path>
                </svg>
              ) : (
                ''
              )}
              CONFIRM
            </Button>
          </div>
        </Modal.Body>
      </Form>
    </Modal>
  );
}
