import React from 'react';
import {Modal, Button, Row, Col, Form, Container} from 'react-bootstrap';

export function ModalTax({show, onHide, taxFullname, taxIdNumber, onConfirm, onCancel}) {
  function handleSubmit(event) {
    event.preventDefault();
    onConfirm();
  }

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
      backdrop="static">
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Container
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
            <Form.Label style={{fontFamily: 'Roboto,Arial,Helvetica,sans-serif'}}>
              Please check that the details below are correct:
            </Form.Label>
            <Form.Label style={{fontFamily: 'Roboto,Arial,Helvetica,sans-serif'}}>
              Tax Recipient Full Name: {taxFullname}
            </Form.Label>
            <Form.Label style={{fontFamily: 'Roboto,Arial,Helvetica,sans-serif'}}>
              Tax Recipient ID Number: {taxIdNumber}
            </Form.Label>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'col-lg-12 d-flex justify-content-center'}>
            <Col md="auto">
              <Button
                type="submit"
                style={{
                  minWidth: '10rem',
                  background: 'rgb(0, 150, 136)',
                  fontFamily: 'Montserrat,sans-serif',
                }}>
                Confirm
              </Button>
            </Col>
            <Col md="auto">
              <Button
                onClick={onCancel}
                style={{
                  minWidth: '10rem',
                  fontFamily: 'Montserrat,sans-serif',
                }}
                variant="danger">
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
