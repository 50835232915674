import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import queryString from 'query-string';
import './index.css';

export function ThanksMessages() {
  const [idDonation, setIdDonation] = useState('');
  const [dataForm, setDataForm] = useState(null);
  const [status, setStatus] = useState('success');

  const history = useHistory();

  useEffect(() => {
    if (history?.location?.data !== undefined) {
      setDataForm(history?.location?.data);
    } else {
      const dataStorage = JSON.parse(localStorage.getItem('dataForm'));
      setDataForm(dataStorage);
    }

    let regex = /\/thank-you\/\/([^/]+)/;
    let match = history?.location?.pathname?.match(regex);
    if (match) {
      setIdDonation(match[1]);
    } else {
      setIdDonation(history?.location?.data?.donation.number);
    }

    if (!!history.location.search) {
      let search = queryString.parse(history.location.search);
      setIdDonation(search.donationNumber);
      setDataForm({
        ...dataForm,
        amount: search.amount,
        frequency: /^recurring$/i.test(search?.type) ? 0 : 1,
      });
      setStatus(search.status);
    }
    window.scrollTo(0, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="thanks-message" style={{padding: '30px', textAlign: 'center'}}>
        <div className="donation-success-info">
          <p
            style={{
              fontFamily: 'Montserrat,sans-serif',
              fontWeight: 'bolder',
              fontSize: '18px',
              marginBottom: '20px',
            }}>
            Your donation is{' '}
            {status === 'success' ? (
              <b className="text-success">Successful</b>
            ) : (
              <b className="text-danger">Failed</b>
            )}
          </p>
          <p
            style={{
              fontFamily: 'Roboto',

              fontSize: '16px',
            }}>
            <b>Donation Details</b>
          </p>
          <p
            style={{
              fontFamily: 'Roboto',

              fontSize: '16px',
            }}>
            Amount donated: $
            {dataForm?.amount === 'Other' ? dataForm?.inputAmount : dataForm?.amount}
            <br />
            Donation type: {dataForm?.frequency === 0 ? 'Monthly Donation' : 'Onetime Donation'}
            <br />
            Donation number: {idDonation}
            <br />
          </p>
          <p
            style={{
              fontFamily: 'Roboto',

              fontSize: '16px',
            }}>
            An email with your donation information has been sent to you. Please call 1800 375 8600
            or email <a href="mailto:donate@lb.org.sg">donate@lb.org.sg</a> if you do not receive
            it.
          </p>
        </div>
      </div>
    </>
  );
}
